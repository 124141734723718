.contact-screen {
  background-color: #000;
  min-height: 100vh;

  position: relative;
}

.contact-screen__heading {
  color: #ffffff;
  font-size: 4.5rem;
  text-align: center;
  padding: 4rem;

  animation: leftToRightAnimation 2s ease-in-out;
}
.contact-screen__form p {
  color: #bbb;
  font-size: 1.6rem;
  text-align: center;
}

.form-map__wrapper {
  width: 110rem;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

form {
  margin-top: 3rem;
}

p#message__para {
  color: #ff2e63;
  display: none;
}
.form-group {
  padding: 1.5rem 5rem;
}

.form-group input {
  font-size: 1.5rem;
  width: 40rem;
  padding: 1rem 0.8rem;
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;

  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.form-group textarea {
  font-family: inherit;
  font-size: 1.5rem;
  width: 40rem;
  height: 10rem;
  resize: none;
  padding: 1rem 0.8rem;
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-group textarea::-webkit-input-placeholder {
  color: #ffffff;
}

form button[type='submit'] {
  margin-top: 2rem;
  padding: 1.5rem 4rem;
  font-size: 1.9rem;
  cursor: pointer;

  border: none;
  outline: none;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

form button[type='submit']:hover {
  animation: animate 8s linear infinite;
}

form button[type='submit']::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  z-index: -1;
  opacity: 0;
  transition: 0.5s;
}
form button[type='submit']:hover::before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

footer {
  width: 100%;
  height: 10rem;
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1a1c20;

  /* z-index: 2; */
}

p.mail-para {
  color: #bbb;
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
}

p.mail-para a {
  text-decoration: none;
  color: #fff;
}

.contact-screen__social-media {
  font-size: 1.9rem;
  text-align: center;
}

.contact-screen__social-media a {
  margin-right: 1.5rem;
  color: #bbb;
}

/* animate  Button Animation*/
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

@media only screen and (max-width: 1150px) {
  .form-map__wrapper {
    margin: 0;
    display: flex;
    flex-direction: column;

    width: 100%;
    text-align: center;
  }
  .contact-screen__map {
    margin-top: 4rem;
    margin-bottom: 15rem;
  }
}

@media only screen and (max-width: 700px) {
  .contact-screen {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .form-group input {
    width: 100%;
  }
  .form-group textarea {
    width: 100%;
  }
  .contact-screen__map {
    width: 100%;
    text-align: center;
  }
  .contact-screen__map iframe {
    width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  footer {
    height: 13rem;
  }
}
