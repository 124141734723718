@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem=10px */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Blogs screen */
.blogs-screen {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 5rem;
  padding-top: 5rem;
}
