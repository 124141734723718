@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem=10px */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Blogs screen */
.blogs-screen {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 5rem;
  padding-top: 5rem;
}

.container {
  overflow-x: hidden;
}

span.menu-bar {
  width: 4rem;
  font-size: 4rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #111;
  width: 25rem;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.navbar__logo {
  text-align: center;
  z-index: 1000;
  margin-bottom: 5rem;
}

.navbar__logo img {
  width: 12rem;
  height: 13rem;
}

.navbar a:link,
.navbar a {
  text-decoration: none;
  padding-left: 10rem;
  padding-bottom: 2rem;
  font-size: 2rem;
  z-index: 1000;
  color: #fff;
  transition: all 0.3s ease-in-out;

  position: relative;
}

.navbar a::after {
  content: '';
  position: absolute;
  top: 3.5rem;
  left: 1rem;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.025);
}

.navbar a:active,
.navbar a.active {
  color: #f6416c;
}
.navbar a:hover {
  color: #903749;
}

.navbar a.close-btn {
  font-size: 5rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0;
}
.navbar a.close-btn:hover {
  color: red;
}

.navbar p {
  position: absolute;
  bottom: 3rem;
  text-align: center;
  color: #bbb;

  width: 100%;
}

@media only screen and (max-height: 615px) {
  .navbar__logo img {
    width: 10rem;
    height: 10rem;
  }
  .navbar__logo {
    margin-bottom: 1rem;
  }
}

.home-screen {
  height: 100vh;
  background-image: url(/static/media/Bg.fdf5070f.jpg);
  background-size: cover;
  background-position: center;
  position: relative;

  overflow: hidden;
}
.home-screen__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.home-screen__particles-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

#tsparticles {
  height: 100%;
  width: 100%;
  z-index: 5;
}

.home-screen__div {
  height: auto;
  width: 100%;
  z-index: 100;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.home-screen__div-image {
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  margin: auto;
  display: inline-block;
  object-fit: cover;
  margin-bottom: 2.5rem;
  margin-top: -12rem;
}
.home-screen__img {
  width: 100%;
  height: 100%;

  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.09);
}

.home-screen__name {
  font-size: 4rem;
  color: #fff;

  -webkit-animation: leftToRightAnimation 2s ease-in-out;

          animation: leftToRightAnimation 2s ease-in-out;
}

span.name {
  color: #ff2e63;
}

p.home-screen__about {
  font-size: 1.9rem;
}

span.type {
  color: #fff;
}

@-webkit-keyframes leftToRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }
  80% {
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes leftToRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }
  80% {
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.about-screen {
  background-color: #000;
  min-height: 100vh;
  color: #bbb;
}
.about-screen__title p {
  font-size: 1.3rem;
  letter-spacing: 1.5px;
  color: #54e346;
}

.about-screen__title {
  width: 120rem;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.about-screen__title h1 {
  color: #fff;
  font-size: 3rem;
}

.left-right__wrapper {
  display: flex;
  align-items: flex-start;

  width: 120rem;
  margin: 0 auto;
}
.about-screen__left {
  margin-right: 5rem;
}

.about-screen__left img {
  width: 40rem;
  height: 45rem;
  border-radius: 5px;
}

.about-screen__right {
  flex: 0 0 50%;
}

.about-screen__right h1 {
  font-size: 3rem;
  margin-top: -1.7rem;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  color: #111;
}

.about-screen__right h1 span {
  -webkit-animation: aboutAnimate 2.75s linear infinite;
          animation: aboutAnimate 2.75s linear infinite;
}

.about-screen__right h1 span:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.about-screen__right h1 span:nth-child(2) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.about-screen__right h1 span:nth-child(3) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.about-screen__right h1 span:nth-child(4) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.about-screen__right h1 span:nth-child(5) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.about-screen__right h1 span:nth-child(6) {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}
.about-screen__right h1 span:nth-child(7) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.about-screen__right h1 span:nth-child(8) {
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s;
}
.about-screen__right h1 span:nth-child(9) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.about-screen__right h1 span:nth-child(10) {
  -webkit-animation-delay: 2.25s;
          animation-delay: 2.25s;
}
.about-screen__right h1 span:nth-child(11) {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}
@-webkit-keyframes aboutAnimate {
  0%,
  100% {
    color: #fff;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff,
      0 0 80px #00b3ff, 0 0 120px #00b3ff, 0 0 200px #00b3ff, 0 0 300px #00b3ff,
      0 0 400px #00b3ff;
  }
  5%,
  95% {
    color: #111;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    text-shadow: none;
  }
}
@keyframes aboutAnimate {
  0%,
  100% {
    color: #fff;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff,
      0 0 80px #00b3ff, 0 0 120px #00b3ff, 0 0 200px #00b3ff, 0 0 300px #00b3ff,
      0 0 400px #00b3ff;
  }
  5%,
  95% {
    color: #111;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    text-shadow: none;
  }
}

.about-screen__right p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.5px;
  margin-bottom: 2rem;
}
.about-screen__right p span {
  color: #54e346;
  font-style: italic;
}
.about-screen__details {
  margin-bottom: 4rem;
}

.about-screen__details ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-screen__details ul li {
  flex: 0 0 50%;
  padding-bottom: 1.4rem;
  list-style: none;
}

.about-screen__details ul li i {
  color: #54e346;
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
}

.about-screen__details ul li span {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.about-screen__details ul li span a {
  text-decoration: none;
  color: #fff;
}

.about-screen__details ul li span label {
  color: #fff;
  display: inline-block;
  min-width: 85px;
}

.about-screen__button a {
  display: inline-block;
  color: #255784;
  background-color: #2196f3;
  text-decoration: none;
  margin: 1rem;
  margin-left: 3rem;
  padding: 1.5rem 4rem;
  font-size: 1.8rem;
  border-radius: 5px;
  box-shadow: 0 0 10px #2196f3, 0 0 30px red;
  font-weight: bold;
  transition: 0.2s;
  transform: scale(1);
}

.about-screen__button a:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px #2196f3, 0 0 30px #2196f3, 0 0 70px #2196f3;
}

.about-screen__skills h1 {
  color: #fff;
  margin-top: 5rem;
  text-align: center;
  font-size: 2.5rem;

  margin-left: -5rem;
}

.about-screen__skills-list {
  width: 80rem;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.about-screen__skills-list ul {
  display: flex;
  flex-wrap: wrap;
}

.about-screen__skills-list ul li {
  list-style: none;
  flex: 0 0 5%;
  font-size: 2rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background-color: #903749;
  border-radius: 30px;
}

.about-screen__skills-list ul li a {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 1250px) {
  .about-screen {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .about-screen__title {
    width: 100%;
    margin: 0;
  }
  .left-right__wrapper {
    width: 100%;
    margin: 0;
  }

  .about-screen__skills-list {
    margin: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .about-screen {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .left-right__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-screen__left {
    width: 100%;
    margin-right: 0;
  }

  .about-screen__right {
    margin-top: 5rem;
    width: 100%;
  }
  .about-screen__button a {
    margin: 0;
  }
  .about-screen__details ul {
    display: flex;
    flex-direction: column;
  }

  .about-screen__right p {
    font-size: 1.5rem;
  }
  .about-screen__button {
    width: 100%;
    text-align: center;
  }
  .about-screen__skills-list {
    width: 100%;
  }
  .about-screen__skills-list ul li {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 430px) {
  .about-screen__left img {
    width: 38rem;
    height: 45rem;
  }
}

@media only screen and (max-width: 400px) {
  .about-screen__left img {
    width: 30rem;
    height: 35rem;
  }
}
@media only screen and (max-width: 319px) {
  .about-screen__left img {
    width: 27rem;
    height: 35rem;
  }
}

@media only screen and (max-width: 300px) {
  .about-screen__left img {
    width: 25rem;
    height: 30rem;
  }
}

.contact-screen {
  background-color: #000;
  min-height: 100vh;

  position: relative;
}

.contact-screen__heading {
  color: #ffffff;
  font-size: 4.5rem;
  text-align: center;
  padding: 4rem;

  -webkit-animation: leftToRightAnimation 2s ease-in-out;

          animation: leftToRightAnimation 2s ease-in-out;
}
.contact-screen__form p {
  color: #bbb;
  font-size: 1.6rem;
  text-align: center;
}

.form-map__wrapper {
  width: 110rem;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

form {
  margin-top: 3rem;
}

p#message__para {
  color: #ff2e63;
  display: none;
}
.form-group {
  padding: 1.5rem 5rem;
}

.form-group input {
  font-size: 1.5rem;
  width: 40rem;
  padding: 1rem 0.8rem;
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;

  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.form-group textarea {
  font-family: inherit;
  font-size: 1.5rem;
  width: 40rem;
  height: 10rem;
  resize: none;
  padding: 1rem 0.8rem;
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-group textarea::-webkit-input-placeholder {
  color: #ffffff;
}

form button[type='submit'] {
  margin-top: 2rem;
  padding: 1.5rem 4rem;
  font-size: 1.9rem;
  cursor: pointer;

  border: none;
  outline: none;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

form button[type='submit']:hover {
  -webkit-animation: animate 8s linear infinite;
          animation: animate 8s linear infinite;
}

form button[type='submit']::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  z-index: -1;
  opacity: 0;
  transition: 0.5s;
}
form button[type='submit']:hover::before {
  -webkit-filter: blur(20px);
          filter: blur(20px);
  opacity: 1;
  -webkit-animation: animate 8s linear infinite;
          animation: animate 8s linear infinite;
}

footer {
  width: 100%;
  height: 10rem;
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1a1c20;

  /* z-index: 2; */
}

p.mail-para {
  color: #bbb;
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
}

p.mail-para a {
  text-decoration: none;
  color: #fff;
}

.contact-screen__social-media {
  font-size: 1.9rem;
  text-align: center;
}

.contact-screen__social-media a {
  margin-right: 1.5rem;
  color: #bbb;
}

/* animate  Button Animation*/
@-webkit-keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

@media only screen and (max-width: 1150px) {
  .form-map__wrapper {
    margin: 0;
    display: flex;
    flex-direction: column;

    width: 100%;
    text-align: center;
  }
  .contact-screen__map {
    margin-top: 4rem;
    margin-bottom: 15rem;
  }
}

@media only screen and (max-width: 700px) {
  .contact-screen {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .form-group input {
    width: 100%;
  }
  .form-group textarea {
    width: 100%;
  }
  .contact-screen__map {
    width: 100%;
    text-align: center;
  }
  .contact-screen__map iframe {
    width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  footer {
    height: 13rem;
  }
}

.preloader-animation {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  /* background-color: red; */
  width: 10rem;
  height: 10rem;

  -webkit-animation: preloaderAnimation 1s linear infinite;

          animation: preloaderAnimation 1s linear infinite;
}

.loader span {
  position: absolute;
  width: 5rem;
  height: 5rem;

  -webkit-animation: rotationAnimation 1s linear infinite;

          animation: rotationAnimation 1s linear infinite;
}

.loader span:nth-child(1) {
  top: 0;
  left: 0;
  background-color: #f79f1f;
}

.loader span:nth-child(2) {
  top: 0;
  right: 0;
  background-color: #12cbc4;
}
.loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  background-color: #ed4c67;
}
.loader span:nth-child(4) {
  bottom: 0;
  right: 0;
  background-color: #a3cb38;
}

@-webkit-keyframes preloaderAnimation {
  0% {
    width: 10rem;
    height: 10rem;
  }
  10% {
    width: 10rem;
    height: 10rem;
  }
  50% {
    width: 15rem;
    height: 15rem;
  }
  90% {
    width: 10rem;
    height: 10rem;
  }
  100% {
    width: 10rem;
    height: 10rem;
  }
}

@keyframes preloaderAnimation {
  0% {
    width: 10rem;
    height: 10rem;
  }
  10% {
    width: 10rem;
    height: 10rem;
  }
  50% {
    width: 15rem;
    height: 15rem;
  }
  90% {
    width: 10rem;
    height: 10rem;
  }
  100% {
    width: 10rem;
    height: 10rem;
  }
}

@-webkit-keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  90% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  90% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.photo-screen {
  background-color: #000;
  color: #fff;

  min-height: 100vh;
}

.photo-screen h1 {
  text-align: center;
  font-size: 4rem;
  padding-top: 2rem;

  -webkit-animation: leftToRightAnimation 2s ease-in-out 3s;

          animation: leftToRightAnimation 2s ease-in-out 3s;
}

.photo-screen p {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 3.5rem;
  letter-spacing: 1px;
  color: #bbb;
}

.photo-screen__img-conatainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(12, 15rem);
  grid-gap: 1rem;
  gap: 1rem;

  max-width: 110rem;
  margin: 0 auto;

  padding-bottom: 2rem;
}

.img__div img {
  width: 100%;
  height: 100%;

  /* object-fit: cover; */
  border-radius: 4px;
  display: block;
  transition: all 0.3s;
}

.img__div img:hover {
  -webkit-filter: brightness(0.6);
          filter: brightness(0.6);
}

.img__div {
  overflow: hidden;
}

.img__div-1 {
  grid-row: 1 / 3;
  grid-column: 1 / 4;
}

.img__div-2 {
  grid-row: 7 / 8;
  grid-column: 7 / -1;
}

.img__div-3 {
  grid-row: 1 / 3;
  grid-column: 7 / -1;
}

.img__div-4 {
  grid-row: 10 / 11;
  grid-column: 1 / 3;
}

.img__div-5 {
  grid-row: 5 / 7;
  grid-column: 5 / 7;
}

.img__div-6 {
  grid-row: 3 / 5;
  grid-column: 5 / -1;
}

.img__div-7 {
  grid-row: 11 / 12;
  grid-column: 7 / -1;
}

.img__div-8 {
  grid-row: 10 / 11;
  grid-column: 3 / 5;
}

.img__div-9 {
  grid-row: 5 / 6;
  grid-column: 1 / 3;
}

.img__div-10 {
  grid-row: 5 / 6;
  grid-column: 3 / 5;
}

.img__div-11 {
  grid-row: 3 / 5;
  grid-column: 1 / 5;
}

.img__div-12 {
  grid-row: 5 / 7;
  grid-column: 7 / -1;
}

.img__div-13 {
  grid-row: 6 / 8;
  grid-column: 1 / 5;
}

.img__div-14 {
  grid-row: 12 / 13;
  grid-column: 5 / 7;
}
.img__div-15 {
  grid-row: 7 / 8;
  grid-column: 5 / 7;
}

.img__div-16 {
  grid-row: 1 / 3;
  grid-column: 4 / 7;
}

.img__div-17 {
  grid-row: 8 / 10;
  grid-column: 1 / 5;
}

.img__div-18 {
  grid-row: 10 / 12;
  grid-column: 5 / 7;
}

.img__div-19 {
  grid-row: 8 / 10;
  grid-column: 5 / 7;
}

.img__div-20 {
  grid-row: 8 / 9;
  grid-column: 7 / -1;
}

.img__div-21 {
  grid-row: 11 / 13;
  grid-column: 1 / 5;
}
.img__div-22 {
  grid-row: 9 / 11;
  grid-column: 7 / -1;
}
.img__div-23 {
  grid-row: 12 / 13;
  grid-column: 7 / -1;
}

@media only screen and (max-width: 1100px) {
  .photo-screen__img-conatainer {
    max-width: 80rem;
  }
}

@media only screen and (max-width: 800px) {
  .photo-screen {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .photo-screen__img-conatainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .img__div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .img__div img {
    width: 75%;
  }
}

@media only screen and (max-width: 500px) {
  .img__div img {
    width: 100%;
  }
}

/* #010615 */

.project-screen {
  background-color: #010615;
  background-color: #000;
  min-height: 100vh;
  color: #fff;
}

.project-screen h1 {
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
  font-size: 5rem;
  color: #fff;

  -webkit-animation: leftToRightAnimation 2s ease-in-out;

          animation: leftToRightAnimation 2s ease-in-out;
}

.project-screen__card-wrapper {
  display: flex;
  flex-wrap: wrap;

  max-width: 40rem;
  margin: 0 auto;
}

.project-screen__card-wrapper a {
  text-decoration: none;

  flex: 0 0 100%;
}

.card {
  width: 100%;
  height: 60rem;
  background-color: #060c21;
  position: relative;
  z-index: 0;

  margin-bottom: 5rem;
}

.card:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  background-color: #fff;
  z-index: -1;
}

.card:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  background-color: #fff;
  -webkit-filter: blur(40px);
          filter: blur(40px);
  z-index: -2;
}

.card-one:before,
.card-one:after {
  background: linear-gradient(235deg, #89ff00, #010615, #00bcd4);
}

.card-two:before,
.card-two:after {
  background: linear-gradient(235deg, #ffee00fb, #010615, #f32121);
}

.card-three:before,
.card-three:after {
  background: linear-gradient(235deg, #2aff5f, #010615, #f39821);
}

.card-four:before,
.card-four:after {
  background: linear-gradient(235deg, #ff00fe, #010615, #fbff00);
}

.card__upper {
  width: 100%;
  height: 30rem;
  background-color: #060c21;
}

.card__upper img {
  width: 100%;
  height: 100%;
}

.card__lower {
  height: 30rem;
  background-color: #060c21;
}

.card__lower h2 {
  font-size: 1.8rem;
  text-align: center;
  padding: 1.5rem;
  color: #bbb;
  letter-spacing: 1px;
}

.card__lower-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 1rem;
}

.card__lower-list ul li {
  list-style: none;
  flex: 0 0 30%;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: #903749;
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .project-screen {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .project-screen__card-wrapper {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .project-screen__card-wrapper a {
    width: 100%;
  }
  .card {
    width: 100%;
  }
}

