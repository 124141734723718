.home-screen {
  height: 100vh;
  background-image: url('../images/Bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;

  overflow: hidden;
}
.home-screen__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.home-screen__particles-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

#tsparticles {
  height: 100%;
  width: 100%;
  z-index: 5;
}

.home-screen__div {
  height: auto;
  width: 100%;
  z-index: 100;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.home-screen__div-image {
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  margin: auto;
  display: inline-block;
  object-fit: cover;
  margin-bottom: 2.5rem;
  margin-top: -12rem;
}
.home-screen__img {
  width: 100%;
  height: 100%;

  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.09);
}

.home-screen__name {
  font-size: 4rem;
  color: #fff;

  animation: leftToRightAnimation 2s ease-in-out;
}

span.name {
  color: #ff2e63;
}

p.home-screen__about {
  font-size: 1.9rem;
}

span.type {
  color: #fff;
}
