span.menu-bar {
  width: 4rem;
  font-size: 4rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #111;
  width: 25rem;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.navbar__logo {
  text-align: center;
  z-index: 1000;
  margin-bottom: 5rem;
}

.navbar__logo img {
  width: 12rem;
  height: 13rem;
}

.navbar a:link,
.navbar a {
  text-decoration: none;
  padding-left: 10rem;
  padding-bottom: 2rem;
  font-size: 2rem;
  z-index: 1000;
  color: #fff;
  transition: all 0.3s ease-in-out;

  position: relative;
}

.navbar a::after {
  content: '';
  position: absolute;
  top: 3.5rem;
  left: 1rem;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.025);
}

.navbar a:active,
.navbar a.active {
  color: #f6416c;
}
.navbar a:hover {
  color: #903749;
}

.navbar a.close-btn {
  font-size: 5rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0;
}
.navbar a.close-btn:hover {
  color: red;
}

.navbar p {
  position: absolute;
  bottom: 3rem;
  text-align: center;
  color: #bbb;

  width: 100%;
}

@media only screen and (max-height: 615px) {
  .navbar__logo img {
    width: 10rem;
    height: 10rem;
  }
  .navbar__logo {
    margin-bottom: 1rem;
  }
}
