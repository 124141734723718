.preloader-animation {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  /* background-color: red; */
  width: 10rem;
  height: 10rem;

  animation: preloaderAnimation 1s linear infinite;
}

.loader span {
  position: absolute;
  width: 5rem;
  height: 5rem;

  animation: rotationAnimation 1s linear infinite;
}

.loader span:nth-child(1) {
  top: 0;
  left: 0;
  background-color: #f79f1f;
}

.loader span:nth-child(2) {
  top: 0;
  right: 0;
  background-color: #12cbc4;
}
.loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  background-color: #ed4c67;
}
.loader span:nth-child(4) {
  bottom: 0;
  right: 0;
  background-color: #a3cb38;
}

@keyframes preloaderAnimation {
  0% {
    width: 10rem;
    height: 10rem;
  }
  10% {
    width: 10rem;
    height: 10rem;
  }
  50% {
    width: 15rem;
    height: 15rem;
  }
  90% {
    width: 10rem;
    height: 10rem;
  }
  100% {
    width: 10rem;
    height: 10rem;
  }
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  90% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
