@keyframes leftToRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }
  80% {
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
